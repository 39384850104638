<template>
  <div class="news">
    <div class="banner"><img src="@/assets/img/news_banner.jpg" alt="">
      <h3>信息公示</h3>
    </div>
    <div class="container">

      <ul class="news-data">
        <li v-for="(item, index) in list">
          <router-link :to="{ path: 'Article', query: { id: item.id, title: item.title, content: item.content } }">
            <div class="media">
              <div class="media-left">
                <img class="media-object" :src="item.picture" alt="...">
              </div>
              <div class="media-body">
                <p class="media-updateTime" v-html="item.updateTime"></p>
                <h4 class="media-heading">{{ item.title }}</h4>
                <p class="media-content" v-html="item.content"></p>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
      <div class="pagination">
        <a-pagination v-model:current="current" :total="total" :pageSize="pageSize" :hideOnSinglePage="true">
          <template #itemRender="{ type, originalElement }">
            <a v-if="type === 'prev'">上一页</a>
            <a v-else-if="type === 'next'">下一页</a>
            <component :is="originalElement" v-else></component>
          </template>
        </a-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { news } from "@/api";

export default {
  name: "News"
  , data() {
    return {
      list: []
      , total: 50
      , current: 1
      , pageSize: 20
    }
  }
  , created() {
    const columnId = this.$route.query.columnId;
    // console.log("News columnId:",columnId);
    const queryParam = { id: columnId }
    this.getList(queryParam)
  }
  , methods: {

    getList(data) {
      news(data).then(response => {
        // console.log("news getlist :",response)
        this.list = response.data.list;
        this.total = response.data.total;
      })
    },

  }
}
</script>

<style scoped lang="scss">
.banner {
  position: relative;

  &>img {
    width: 100%;
  }

  &>h3 {
    font-size: 46px;
    color: #fff;
    font-weight: 500;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
}

@media (min-width: 320px) {
  .banner {
    height: 200px;

    &>img {
      height: 200px;
    }

  }
}

@media (min-width: 768px) {
  .banner {
    height: 300px;

    &>img {
      height: 300px;
    }
  }

}

@media (min-width: 992px) {
  .banner {
    height: 400px;

    &>img {
      height: 400px;
    }
  }
}

@media (min-width: 1200px) {
  .banner {
    height: 400px;

    &>img {
      height: 400px;
    }

  }
}

h3 {
  line-height: 2;
  font-size: 24px;
  color: #333333;
  text-transform: uppercase;
  margin: 40px 0;
  text-align: center;
}


.news {

  & .news-data {
    &>li {
      padding: 36px 0;
      border-bottom: 1px solid #e5e5e5;
      position: relative;
      overflow: hidden;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  & .media {
    &>.media-left {
      float: left;
      width: 22%;

      & .media-object {
        display: block;
        width: 100%;
        height: 166px;
        border: 1px solid #eee;
        box-shadow: 2px 2px 1px #bbb;
      }
    }

    &>.media-body {
      float: left;
      padding-left: 20px;
      width: 78%;
      height: 166px;
      overflow: hidden;
      text-overflow: ellipsis;

      &>.media-heading {
        font-size: 18px;
        line-height: 30px;
        font-weight: 600;
        margin-bottom: 15px;
        color: #333;
      }

      &>.media-updateTime {
        font-size: 10px;
        color: #666;
        margin-bottom: 8px;
        margin-top: 10px;
      }

      &>.media-content {
        font-size: 15px;
        text-indent: 2rem;
        text-align: justify;
        line-height: 30px;
        color: #666;
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>